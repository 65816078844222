<template>
	<div v-if="model" class="box">
		<div class="form-group">
			<label>{{ $t('model.nom_modele') }}</label>
			<input type="text" v-model="model.model_label" class="form-control">
		</div>

		<div class="form-group">
			<b-form-checkbox
			    id="model_default"
			    v-model="model.model_default"
			    :unchecked-value="false"
				:checked-value="true"
			>
			{{ $t('model.model_default') }}
			</b-form-checkbox>
		</div>

		<div class="form-group" v-if="model.type.modeltype_template == 'facture' || model.type.modeltype_template == 'quotation'">
			<label for="">{{ $t('model.choix_template_entete') }}</label>
			<e-select
				v-model="model_template_header"
				id="model_template_header"
				track-by="template_code"
				label="template_code"
				:placeholder="labelTemplateHeader"
				:selectedLabel="selectedLabel"
				:options="templates_header"
				:allow-empty="false"
				:show-labels="false"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ $t('model.' + option.template_code) }}</template>
				<template slot="option" slot-scope="{ option }">
					{{ $t('model.' + option.template_code) }}
				</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>

		<b-tabs content-class="mt-3">
			<b-tab
				v-for="locale in locales"
				:key="locale.code"
				:title="locale.title"
				:active="locale.code === localeSelected.code"
				title-link-class="border"
			>
				<EditZone
					ref="edit-zone"
					:model="model_lang[locale.code]"
					:modelable_id="intern_modelable_id"
					:modelable_type="intern_modelable_type"
					:model_id="intern_model_id"
					:class_perso="class_perso"
					:lang="locale.code"
				/>
			</b-tab>
		</b-tabs>

		<div class="text-center mt-5">
			<b-button
				v-if="preview_pdf && !save_pdf && !mail"
				variant="primary"
				@click.prevent="previewPdf"
				pill
				class="mx-auto"
			>
				{{ $t('action.save_and_previsualiser') }}
				<font-awesome-icon v-if="!busy" :icon="['far', 'file']" />
				<font-awesome-icon v-else :icon="['fas', 'spinner']" pulse />
			</b-button>

			<b-button
				v-else-if="preview_pdf && !mail && preview_type"
				variant="secondary"
				@click.prevent="previewPdf"
				pill
				class="mr-2"
			>
				{{ $t('action.previsualiser') }}
				<font-awesome-icon v-if="!busy" :icon="['far', 'file']" />
				<font-awesome-icon v-else :icon="['fas', 'spinner']" pulse />
			</b-button>

			<b-button
				v-if="(preview_pdf && save_pdf) || !preview_pdf || mail"
				variant="primary"
				@click.prevent="saveForm"
				pill
				class="mx-auto"
			>
				{{ $t('global.enregistrer') }}
				<font-awesome-icon v-if="!busy" :icon="['fal', 'save']" />
				<font-awesome-icon v-else :icon="['fas', 'spinner']" pulse />
			</b-button>
		</div>
	</div>
	<div v-else>
		<LoadingSpinner class="col-12" />
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Model from "@/mixins/Model.js"
	import _deepClone from 'lodash/cloneDeep'

	export default {
		name: "Edit",
		mixins: [Model],
		props: {
			modelable_id: {
				type: [Number, String],
				default: () => 0
			},
			modelable_type: {
				type: String,
				default: () => ''
			},
			model_id: {
				type: [Number, String],
				default: () => 0
			},
			class_perso: {
				type: String,
				default: () => 'box'
			},
			preview_type: {
				type: String,
				default: () => "none"
			},
			preview_pdf: {},
			save_pdf: {},
			mail: {
				type: Boolean,
				default: () => false
			}
		},
		data () {
			return {
				model: null,
				model_template_header: {
					template_code: 'base'
				},
				model_lang: {},
				labelTemplateHeader: this.getTrad("model.rechercher_template_header"),
				selectedLabel: this.getTrad("global.selected_label"),
				templates_header: [
					{
						template_code: 'base'
					},
					{
						template_code: 'centre_sans_logo'
					},
					{
						template_code: 'gauche_sans_logo'
					},
					{
						template_code: 'base_gauche'
					},
					{
						template_code: 'centre_sans_logo_gauche'
					},
					{
						template_code: 'gauche_sans_logo_gauche'
					},
					// {
					// 	template_code: 'logo_centre_texte_gauche'
					// },
				],
				busy: false,
				localeSelected: {
					title: this.$t('global.lang_fr'),
					code: 'fr'
				},
				locales: [],
				intern_modelable_id: null,
				intern_model_id: null,
				intern_modelable_type: 'accounting_plan',
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.locales = this.getLocales()

				this.localeSelected = this.locales.find(lang => lang.code == (Vue.i18n.locale()))

				if(this.modelable_id) {
					this.intern_modelable_id = this.modelable_id
					this.intern_modelable_type = this.modelable_type
					this.intern_model_id = this.model_id
				}
				else if(this.modelable_type) {
					this.intern_modelable_id = 0
					this.intern_modelable_type = this.modelable_type
					this.intern_model_id = this.model_id
				}
				else {
					this.intern_modelable_id = this.$route.params.accountingplan_id
					this.intern_model_id = this.$route.params.model_id
				}

				this.intern_modelable_id = this.$sync.replaceWithReplicated(this.intern_modelable_type, this.intern_modelable_id)
				this.intern_model_id = this.$sync.replaceWithReplicated(this.intern_modelable_type, this.intern_model_id)

				const model = (await this.loadModel(this.intern_modelable_id, this.intern_model_id, this.intern_modelable_type))[0]

				this.model = _deepClone(model) 
				if(this.model.type.modeltype_id == 1 || this.model.type.modeltype_id == 26) {
					this.model_template_header = {
						template_code: this.model.model_template_header ?? 'base'
					}
				}

				for(let i in this.locales) {
					this.model_lang[this.locales[i].code] = _deepClone(model)
				}
			},
			async saveForm() {
				try {
					this.busy = true

					// Je vais sauvegarder la case à cocher de modèle par defaut
					await this.updateModel(
						this.intern_modelable_id,
						this.intern_model_id,
						{
							model_default: this.model.model_default,
							model_label: this.model.model_label,
							model_template_header :this.model_template_header.template_code
						},
						this.intern_modelable_type
					)

					const promises = this.$refs['edit-zone'].map(cmp => {
						return cmp.saveTemplate()
					})
					await Promise.all(promises)

					this.successToast()
				}
				finally {
					this.busy = false
				}
			},

			previewPdf() {
				// j'envoie un random pour forcer le sync
				this.$emit("update:preview_pdf", Math.random())
			}
		},
		components: {
			EditZone : () => import('@/components/Model/EditZone'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}
</script>
